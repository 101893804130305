@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ekb94a');
  src:  url('fonts/icomoon.eot?ekb94a#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ekb94a') format('truetype'),
    url('fonts/icomoon.woff?ekb94a') format('woff'),
    url('fonts/icomoon.svg?ekb94a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src:  url('fontss/icomoon.eot?hpb1sw');
  src:  url('fontss/icomoon.eot?hpb1sw#iefix') format('embedded-opentype'),
    url('fontss/icomoon.ttf?hpb1sw') format('truetype'),
    url('fontss/icomoon.woff?hpb1sw') format('woff'),
    url('fontss/icomoon.svg?hpb1sw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'icomoon';
  src:  url('fonts2/icomoon.eot?ki79yg');
  src:  url('fonts2/icomoon.eot?ki79yg#iefix') format('embedded-opentype'),
    url('fonts2/icomoon.ttf?ki79yg') format('truetype'),
    url('fonts2/icomoon.woff?ki79yg') format('woff'),
    url('fonts2/icomoon.svg?ki79yg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-catagory:before {
  content: "\e902";
  color: #fff;
}
.icon-help:before {
  content: "\e903";
  color: #fff;
}
.icon-content:before {
  content: "\e904";
  color: #fff;
}
.icon-review:before {
  content: "\e905";
  color: #fff;
}
.icon-cast:before {
  content: "\e900";
  color: #fff;
}
.icon-users:before {
  content: "\e901";
  color: #fff;
}
.icon-dashboard:before {
  content: "\e906";
  color: #fff;
}

//===============


.icon-sparess-setting:before {
  content: "\e907";
  color: #fff;
}
.icon-profile:before {
  content: "\e908";
  color: #fff;
}
.icon-connect:before {
  content: "\e909";
  color: #fff;
}
.icon-posting:before {
  content: "\e90a";
  color: #fff;
}
.icon-sparess-paid:before {
  content: "\e90b";
  color: #fff;
}
.icon-new-dashbord:before {
  content: "\e90c";
  color: #fff;
}


// 

.icon-file-text2:before {
  content: "\e926";
}
